/** @jsx jsx */
import { jsx, Button, Flex, Text, Spinner, Box } from "theme-ui";
import { useState, useEffect } from "react";
import { path, pathOr } from "ramda";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import moment from "moment-timezone";
import { Link, useParams } from "react-router-dom";
import {
  updateProjectById,
  removeProjectById,
  createGithubProjectTrigger,
} from "redux/modules/projects";
import Page from "containers/Page";
import Header from "components/Header";
import Main from "components/Main";
import Aside from "components/Aside";
import ProjectForm from "components/ProjectForm";
import UserManage from "components/UserManage";
import { selectProject, selectProjectListLoading } from "redux/selectors";

Modal.setAppElement("#app");

const ProjectEdit = () => {
  const { projectUuid } = useParams();
  const project = useSelector((store) => selectProject({ store, projectUuid }));
  const isLoading = useSelector((store) => selectProjectListLoading({ store }));
  const dispatch = useDispatch();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [state, setState] = useState({
    modalIsOpen: false,
  });

  useEffect(() => {
    setState({
      name: pathOr("", ["name"], project),
      archiveValue: "",
      archiveError: "",
      modalIsOpen: false,
      days: pathOr([], ["days"], project),
      timezone: pathOr(moment.tz.guess(), ["timezone"], project),
      time: pathOr(0, ["time"], project),
      meeting_url: pathOr("", ["meeting_url"], project),
      pin: pathOr("", ["pin"], project),
      phone_number: pathOr("", ["phone_number"], project),
      access_code: pathOr("", ["access_code"], project),
    });
  }, [project]);

  const handleOpenModal = (e) => {
    e.preventDefault();
    setState({ ...state, modalIsOpen: true });
  };

  const handleCloseModal = () => {
    setState({
      ...state,
      modalIsOpen: false,
      archiveValue: "",
      archiveError: "",
    });
  };

  const updateProject = (values) => {
    setButtonDisabled(true);
    dispatch(updateProjectById(path(["id"], project), values)).finally(() =>
      setButtonDisabled(false)
    );
  };
  const handleProjectArchive = (e) => {
    e.preventDefault();
    setState({ ...state, modalIsOpen: false });
    dispatch(removeProjectById(path(["id"], project)));
  };

  if (isLoading) {
    return (
      <Page title={`StandupHub | ${state.name}`}>
        <Main>
          <Flex sx={{ justifyContent: "center" }}>
            <Spinner />
          </Flex>
        </Main>
      </Page>
    );
  }

  return (
    <Page title={`StandupHub | ${state.name}`}>
      <Header text={`Editing ${state.name}`} />
      <Main>
        <Box mx={[4, 0, 0]}>
          <ProjectForm
            initValues={{
              name: pathOr("", ["name"], project),
              days: pathOr([], ["days"], project),
              timezone: pathOr(moment.tz.guess(), ["timezone"], project),
              time: pathOr(0, ["time"], project),
              meeting_url: pathOr("", ["meeting_url"], project),
              pin: pathOr("", ["[pin"], project),
              phone_number: pathOr("", ["phone_number"], project),
              access_code: pathOr("", ["access_code"], project),
            }}
            onSubmit={updateProject}
            triggers={path(["triggers"], project)}
            addTrigger={() =>
              dispatch(createGithubProjectTrigger(path(["id"], project)))
            }
          >
            <Flex sx={{ marginY: 4 }}>
              <Button
                disabled={buttonDisabled}
                type="submit"
                variant="primary"
                sx={{ marginRight: 2, width: "100px" }}
              >
                Update
              </Button>
              <Link to="/">
                <Button
                  type="button"
                  variant="secondary"
                  sx={{ marginLeft: 2, width: "100px" }}
                >
                  Cancel
                </Button>
              </Link>
              <Button
                type="button"
                onClick={handleOpenModal}
                variant="dangerous"
                sx={{ marginLeft: "auto" }}
              >
                Archive
              </Button>
            </Flex>
          </ProjectForm>
          <Modal
            isOpen={state.modalIsOpen}
            onRequestClose={handleCloseModal}
            sx={css.modal}
          >
            <div>
              <Text variant="subhead" sx={css.textHeader}>
                Confirm Project Archive
              </Text>
            </div>
            <div>
              <Text>
                When you archive a project, users will no longer be able to give
                standup updates. Are you sure you want to proceed?
              </Text>
              <p>{state.archiveError}</p>
            </div>
            <Flex sx={{ marginTop: 4 }}>
              <Button
                variant="primary"
                onClick={handleProjectArchive}
                sx={{ marginRight: 3 }}
              >
                Confirm Archive
              </Button>
              <Button variant="secondary" onClick={handleCloseModal}>
                Cancel
              </Button>
            </Flex>
          </Modal>
        </Box>
      </Main>
      <Aside>
        <UserManage project={project} />
      </Aside>
    </Page>
  );
};

const css = {
  fieldDiv: {
    marginTop: 7,
  },
  flexDiv: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  textHeader: {
    color: "dark.0",
    marginBottom: 4,
  },
  textBody: {
    color: "dark.1",
    marginBottom: 4,
  },
  gitHookButton: {
    backgroundColor: "dark.0",
  },
  modal: {
    "&.ReactModal__Content": {
      position: "absolute",
      top: "30%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      background: "white",
      padding: 4,
      outline: "1px solid",
      outlineColor: "secondary",
      height: "fit-content",
      maxWidth: 11,
    },
  },
};

export default ProjectEdit;
